import { FileWithPath } from 'react-dropzone';

import { HTTPError } from '../support/errors';

export enum UserRole {
  Trinity_Admin_User = 0,
  Trinity_User = 1,
  Client_Read_Write_User = 2,
  Client_Read_Only_User = 3,
}

export interface User {
  id: number;
  Display_Name: string;
  User_Name: string;
  Role: UserRole;
  ClientID: number | null;
  Extras_Flag: 0 | 1;
}

export type UserDisplayInfo = Pick<User, 'Display_Name' | 'User_Name'>;

export interface Client {
  Name: string;
  id: number;
  updated_at: string;
  updated_by?: User;
}

export interface Profile {
  User: User;
  ModelMemberships: Array<
    Pick<ModelMembership, 'ClientID' | 'ModelID' | 'Role'>
  >;
}

export interface Model {
  Name: string;
  id: number;
}

export enum ModelMembershipRole {
  Contributor = 0,
  Owner = 1,
  Restricted = 2,
}

export interface ModelMembership {
  id: number;
  ClientID: number;
  ModelID: number;
  UserID: number;
  User: User;
  Role: ModelMembershipRole;
  GroupRestrictionLevel: number;
}

export enum ModelInstanceVisibility {
  No_One = 0,
  Trinity_Users = 1,
  Trinity_Users_And_Model_Owners = 2,
  All_User = 3,
}

export enum Timescale {
  Yearly = 'Yearly',
  Quarterly = 'Quarterly',
  Monthly = 'Monthly',
  Weekly = 'Weekly',
}

export interface XRefDate {
  Date: string;
  DateID: number;
  Timescale: Timescale;
}

export interface ModelInstance {
  Name: string;
  Visibility: ModelInstanceVisibility;
  Timescale: Timescale;
  Monthly_Flag: 0 | 1;
  Quarterly_Flag: 0 | 1;
  Yearly_Flag: 0 | 1;
  id: number;
  updated_at: string;
  created_at: string;
  State: number;
  Start_DateID: number;
  Published: boolean;
  ModelID: number;
  End_DateID: number;
  Editable: boolean;
  Draft: boolean;
  IsCalculationEnabled: boolean;
}
export enum ModelInstanceArchiveFlag {
  Current = 0,
  Archive = 1,
}

export enum ScenarioEditState {
  Locked = 0,
  OwnerEdit = 1, // This should be called "Trinity User or Model Owner"
  WorldEdit = 2,
}

export enum ScenarioArchiveFlag {
  Current = 0,
  Archive = 1,
}

export interface Scenario {
  Name: string;
  Year: number;
  Archive_Flag: ScenarioArchiveFlag;
  Edit_State: ScenarioEditState;
  ModelInstanceID: number;
  id: number;
  created_at: string;
  updated_at: string;
  updated_by?: UserDisplayInfo;
  LockSubmissionID: number;
}

export enum SubmissionStatus {
  Queued = 0,
  Started = 1,
  Finished = 2,
  Failed = -1,
  FailedWithReturnFile = -2,
  NoDataReturned = -5,
}

export interface Submission {
  id: number;
  ScenarioID: number;
  UserID?: number;
  User?: UserDisplayInfo;
  Comment: string;
  Status: SubmissionStatus;
  Status_Message?: string;
  created_at: string;
  GroupRowIdentifierID?: number;
  version: string;
  ErrorBlobName: string;
  ErrorBlobURL: string;
}

export interface Upload {
  id: number;
  UserID?: number;
  Comment: string;
  FileName: string;
  BlobName: string;
  BlobURL: string;
  created_at: string;
  Status: SubmissionStatus;
  Status_Message?: string;
}

export interface UploadMultipleToAzure extends Upload {
  fileData?: FileWithPath;
  modelInstanceId: ModelInstance['id'];
}

export interface GeneratedFile {
  id: number;
  FileName: string;
  BlobName: string;
  BlobURL: string;
  Status: SubmissionStatus;
  Status_Message?: string;
  created_at: string;
}

export interface RowIdentifier {
  id: number;
  Type: 'Dimension' | 'DimensionInstance' | 'Input' | 'Output' | 'Group';
  Name: string;
  InputCategory?: string | null;
  TabName?: string | null;
  DimNumber?: number | null;
  GroupID?: number;
  FormatKey?: number | null;
  ModelInstanceID: ModelInstance['id'];
  created_at: string;
  groupsubmissions?: number[];
  DisableInputEdit: boolean;
  DisableOutputAggregation: boolean;
}

export interface RowIdentifierWithDimensionName extends RowIdentifier {
  DimensionName: RowIdentifier['Name'];
}

export enum ReportType {
  Standard = 0,
  Comparison = 1,
  Differences = 2,
  Unpivoted = 3,
  UnpivotedWithComparison = 4,
}

export type FilterType = 0 | 1;

export type RequestStateType =
  | 'idle'
  | 'invalid'
  | 'config'
  | 'busy'
  | 'defineStandardReport'
  | 'defineStandardReportInvalid';

export interface IStandardReport {
  define: string;
  validate: string;
  refresh: string;
}

export interface IGeneratedFiles {
  inputs: string;
  outputs: string;
  standardReport: IStandardReport;
}

export type Source = 'inputs' | 'outputs';

export type SourceDropDown = Source | 'standardReport';

export interface UploadedFile {
  file: File;
  isBusy: boolean;
  error: HTTPError | null;
  fileUploadData: Upload;
  userComment: string;
}

export interface DefaultModelFiles extends RowIdentifier {
  BlobName: string;
  FileName: string;
  GroupRowIdentifierName: RowIdentifier['Name'];
  GroupRowIdentifierID: RowIdentifier['id'];
  ModelInstanceID: ModelInstance['id'];
  User: UserDisplayInfo;
  UserID: User['id'];
  created_at: string;
  id: number;
  BlobURL: string;
}

export enum GroupRestrictionLevel {
  FullAccess = 0,
  ModelLevelAccess = 1,
  ModelInstanceLevelAccess = 2,
}

export interface GroupRestrictionLevelMappings {
  0: 'Full Access';
  1: 'Model Level Access';
  2: 'Model Instance Level Access';
}

export interface ModelRolesObject {
  roleValue: number;
  roleName: string;
}

export const ModelRoles = [
  {
    roleValue: 0,
    roleName: 'Model Contributor',
  },
  {
    roleValue: 1,
    roleName: 'Model Owner',
  },
  {
    roleValue: 2,
    roleName: 'Model Restricted',
  },
];

export interface ModelInstances {
  ModelInstanceID: number;
  ModelInstanceName: string;
  HasGroupAccess: boolean | null;
}
export interface GroupPermissionsObject {
  GroupID: string;
  GroupName: string;
  HasGroupAccessToAllModelInstances: boolean;
  ModelInstances: Array<ModelInstances> | [];
}

export interface GroupPermissionsApiResponse {
  GroupRestrictionLevel: 0 | 1 | 2;
  RestrictionLevel: string;
  UserEmail: string;
  UserName: string;
  GroupPermission: Array<GroupPermissionsObject> | [];
}
